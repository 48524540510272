/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./playback-control-button.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "../../tracks/track-list/playing-indicator/playing-indicator.component.ngfactory";
import * as i10 from "../../tracks/track-list/playing-indicator/playing-indicator.component";
import * as i11 from "./playback-control-button.component";
import * as i12 from "../player.service";
var styles_PlaybackControlButtonComponent = [i0.styles];
var RenderType_PlaybackControlButtonComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PlaybackControlButtonComponent, data: {} });
export { RenderType_PlaybackControlButtonComponent as RenderType_PlaybackControlButtonComponent };
export function View_PlaybackControlButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "play-button"], ["mat-icon-button", ""]], [[2, "visible", null], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.play.emit();
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "play-arrow"]], [[2, "mat-icon-inline", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(4, 638976, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "button", [["class", "pause-button"], ["mat-icon-button", ""]], [[2, "visible", null], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.pause.emit();
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "pause"]], [[2, "mat-icon-inline", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(11, 638976, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "playing-indicator", [], [[2, "visible", null]], null, null, i9.View_PlayingIndicatorComponent_0, i9.RenderType_PlayingIndicatorComponent)), i1.ɵdid(15, 49152, null, 0, i10.PlayingIndicatorComponent, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), i1.ɵncd(null, 0)], function (_ck, _v) { var currVal_4 = "play-arrow"; _ck(_v, 4, 0, currVal_4); var currVal_9 = "pause"; _ck(_v, 11, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.trackIsPlaying(); var currVal_1 = (i1.ɵnov(_v, 1).disabled || null); var currVal_2 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v, 4).inline; _ck(_v, 3, 0, currVal_3); var currVal_5 = _co.trackIsPlaying(); var currVal_6 = (i1.ɵnov(_v, 8).disabled || null); var currVal_7 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = i1.ɵnov(_v, 11).inline; _ck(_v, 10, 0, currVal_8); var currVal_10 = _co.trackIsPlaying(); _ck(_v, 14, 0, currVal_10); }); }
export function View_PlaybackControlButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "playback-control-button", [], null, null, null, View_PlaybackControlButtonComponent_0, RenderType_PlaybackControlButtonComponent)), i1.ɵdid(1, 49152, null, 0, i11.PlaybackControlButtonComponent, [i12.Player], null, null)], null, null); }
var PlaybackControlButtonComponentNgFactory = i1.ɵccf("playback-control-button", i11.PlaybackControlButtonComponent, View_PlaybackControlButtonComponent_Host_0, { track: "track", playing: "playing" }, { play: "play", pause: "pause" }, ["*"]);
export { PlaybackControlButtonComponentNgFactory as PlaybackControlButtonComponentNgFactory };
