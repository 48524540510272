var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { WpUtils } from "../../web-player-utils";
import { ContextMenuComponent } from "../../context-menu/context-menu.component";
var AlbumContextMenuComponent = /** @class */ (function (_super) {
    __extends(AlbumContextMenuComponent, _super);
    function AlbumContextMenuComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        return _this;
    }
    /**
     * Copy fully qualified album url to clipboard.
     */
    AlbumContextMenuComponent.prototype.copyLinkToClipboard = function () {
        _super.prototype.copyLinkToClipboard.call(this, 'album');
    };
    /**
     * Get tracks that should be used by context menu.
     */
    AlbumContextMenuComponent.prototype.getTracks = function () {
        return WpUtils.assignAlbumToTracks(this.data.item.tracks, this.data.item);
    };
    return AlbumContextMenuComponent;
}(ContextMenuComponent));
export { AlbumContextMenuComponent };
