var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { WebPlayerUrls } from "../../web-player-urls.service";
import { Player } from "../../player/player.service";
import { AlbumContextMenuComponent } from "../../albums/album-context-menu/album-context-menu.component";
import { ArtistContextMenuComponent } from "../artist-context-menu/artist-context-menu.component";
import { WebPlayerState } from "../../web-player-state.service";
import { Settings } from "common/core/config/settings.service";
import { filter } from "rxjs/operators";
import { WebPlayerImagesService } from "../../web-player-images.service";
import { ContextMenu } from 'common/core/ui/context-menu/context-menu.service';
var ArtistComponent = /** @class */ (function () {
    /**
     * ArtistComponent Constructor.
     */
    function ArtistComponent(route, router, urls, player, contextMenu, state, settings, wpImages) {
        this.route = route;
        this.router = router;
        this.urls = urls;
        this.player = player;
        this.contextMenu = contextMenu;
        this.state = state;
        this.settings = settings;
        this.wpImages = wpImages;
        /**
         * Active component subscriptions.
         */
        this.subscriptions = [];
        /**
         * Current layout of artist albums in the view.
         */
        this.albumsLayout = 'list';
        /**
         * Whether albums layout toggle button should be visible.
         */
        this.albumsLayoutShouldBeToggleable = true;
        /**
         * Currently active tab.
         */
        this.activeTab = 'overview';
        /**
         * number of popular tracks that should be displayed
         */
        this.popularTracksCount = 5;
    }
    ArtistComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.popularTracksCount = 5;
            _this.artist = data.artist;
        });
        this.setActiveTab(this.router.url);
        this.bindToRouterEvents();
        this.setAlbumsLayout();
    };
    ArtistComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
        this.artist.destroy();
        this.subscriptions = [];
    };
    /**
     * Play all artist tracks from specified track.
     */
    ArtistComponent.prototype.playFrom = function (track) {
        return __awaiter(this, void 0, void 0, function () {
            var tracks, index;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tracks = this.artist.getTracks(), index = tracks.findIndex(function (curr) { return curr === track; });
                        this.player.handleContinuousPlayback = false;
                        return [4 /*yield*/, this.player.overrideQueue({ tracks: tracks.slice(index), queuedItemId: this.artist.get().id })];
                    case 1:
                        _a.sent();
                        this.player.play();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Toggle number of popular tracks that
     * should be displayed between 5 and 20.
     */
    ArtistComponent.prototype.togglePopularTracksCount = function () {
        this.popularTracksCount = this.popularTracksCount === 5 ? 20 : 5;
    };
    /**
     * Show context menu for specified track.
     */
    ArtistComponent.prototype.showAlbumContextMenu = function (album, e) {
        e.stopPropagation();
        this.contextMenu.open(AlbumContextMenuComponent, e.target, { overlayY: 'center', data: { item: album, type: 'album' } });
    };
    /**
     * Open artist context menu.
     */
    ArtistComponent.prototype.showArtistContextMenu = function (e) {
        e.stopPropagation();
        this.contextMenu.open(ArtistContextMenuComponent, e.target, { data: { item: this.artist.get(), type: 'artist' }, originX: 'center', overlayX: 'center' });
    };
    /**
     * Toggle albums layout between grid and list.
     */
    ArtistComponent.prototype.toggleAlbumsLayout = function () {
        if (this.albumsLayout === 'grid') {
            this.albumsLayout = 'list';
        }
        else {
            this.albumsLayout = 'grid';
        }
    };
    /**
     * Bind to router state change events.
     */
    ArtistComponent.prototype.bindToRouterEvents = function () {
        var _this = this;
        var sub = this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) {
            _this.setActiveTab(event.url);
        });
        this.subscriptions.push(sub);
    };
    /**
     * Set currently active tab based on specified url.
     */
    ArtistComponent.prototype.setActiveTab = function (url) {
        var tab = url.split('/').pop();
        switch (tab) {
            case 'about':
                this.activeTab = 'about';
                break;
            case 'similar':
                this.activeTab = 'similar';
                break;
            default:
                this.activeTab = 'overview';
        }
    };
    /**
     * Set albums layout based on current artist provider.
     */
    ArtistComponent.prototype.setAlbumsLayout = function () {
        if (this.settings.get('artist_provider') === 'Discogs') {
            this.albumsLayout = 'grid';
            this.albumsLayoutShouldBeToggleable = false;
        }
        else {
            this.albumsLayout = 'list';
            this.albumsLayoutShouldBeToggleable = true;
        }
    };
    return ArtistComponent;
}());
export { ArtistComponent };
