<div class="scroll-container" customScrollbar>
    <filterable-page-header [filterQuery]="filterQuery" class="margin">
        <span trans>Top 50</span>
    </filterable-page-header>

    <ad-host slot="ads.general_top"></ad-host>

    <track-list [tracks]="filteredItems" [showAlbum]="true" [showArtist]="true"></track-list>

    <no-results-message *ngIf="!filteredItems || ! filteredItems.length">
        <span primary-text trans>No charts found.</span>
        <span secondary-text trans>Could not find charts. Please try again later.</span>
    </no-results-message>

    <ad-host slot="ads.general_top" class="margin-bottom"></ad-host>
</div>