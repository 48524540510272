/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./artist-item.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../lazy-load.directive";
import * as i3 from "../../player/playback-control-button/playback-control-button.component.ngfactory";
import * as i4 from "../../player/playback-control-button/playback-control-button.component";
import * as i5 from "../../player/player.service";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "./artist-item.component";
import * as i9 from "../../web-player-urls.service";
import * as i10 from "../artist.service";
import * as i11 from "../../web-player-images.service";
var styles_ArtistItemComponent = [i0.styles];
var RenderType_ArtistItemComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ArtistItemComponent, data: {} });
export { RenderType_ArtistItemComponent as RenderType_ArtistItemComponent };
export function View_ArtistItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "img-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "img", [["class", "lazy-loaded"]], [[8, "src", 4]], null, null, null, null)), i1.ɵdid(3, 4210688, null, 0, i2.LazyLoadDirective, [i1.ElementRef], { scrollTarget: [0, "scrollTarget"], imgUrl: [1, "imgUrl"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "playback-control-button", [], null, [[null, "play"], [null, "pause"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("play" === en)) {
        var pd_0 = (_co.play() !== false);
        ad = (pd_0 && ad);
    } if (("pause" === en)) {
        var pd_1 = (_co.pause() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_PlaybackControlButtonComponent_0, i3.RenderType_PlaybackControlButtonComponent)), i1.ɵdid(6, 49152, null, 0, i4.PlaybackControlButtonComponent, [i5.Player], { playing: [0, "playing"] }, { play: "play", pause: "pause" }), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["class", "artist-name primary-text"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i7.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.scrollContainer; var currVal_2 = _co.artist.image_small; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _co.playing(); _ck(_v, 6, 0, currVal_3); var currVal_6 = _co.urls.artist(_co.artist); _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.wpImages.getDefault("artist"); _ck(_v, 2, 0, currVal_0); var currVal_4 = i1.ɵnov(_v, 12).target; var currVal_5 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_4, currVal_5); var currVal_7 = _co.artist.name; _ck(_v, 13, 0, currVal_7); }); }
export function View_ArtistItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "artist-item", [["class", "media-grid-item"]], [[2, "active", null]], null, null, View_ArtistItemComponent_0, RenderType_ArtistItemComponent)), i1.ɵdid(1, 180224, null, 0, i8.ArtistItemComponent, [i9.WebPlayerUrls, i10.ArtistService, i5.Player, i11.WebPlayerImagesService], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).playing(); _ck(_v, 0, 0, currVal_0); }); }
var ArtistItemComponentNgFactory = i1.ɵccf("artist-item", i8.ArtistItemComponent, View_ArtistItemComponent_Host_0, { scrollContainer: "scrollContainer", artist: "artist" }, {}, [".secondary-text"]);
export { ArtistItemComponentNgFactory as ArtistItemComponentNgFactory };
