var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { PlayerQueue } from "./player-queue.service";
import { YoutubeStrategy } from "./strategies/youtube-strategy.service";
import { PlayerState } from "./player-state.service";
import { Settings } from "common/core/config/settings.service";
import { FullscreenOverlay } from "../fullscreen-overlay/fullscreen-overlay.service";
import { WebPlayerState } from "../web-player-state.service";
import { Html5Strategy } from "./strategies/html5-strategy.service";
import { SoundcloudStrategy } from "./strategies/soundcloud-strategy.service";
import { TrackPlays } from "./track-plays.service";
import { LocalStorage } from "common/core/services/local-storage.service";
import { BrowserEvents } from "common/core/services/browser-events.service";
var Player = /** @class */ (function () {
    /**
     * Player Constructor.
     */
    function Player(queue, youtube, html5, soundcloud, storage, settings, state, globalState, overlay, browserEvents, trackPlays) {
        this.queue = queue;
        this.youtube = youtube;
        this.html5 = html5;
        this.soundcloud = soundcloud;
        this.storage = storage;
        this.settings = settings;
        this.state = state;
        this.globalState = globalState;
        this.overlay = overlay;
        this.browserEvents = browserEvents;
        this.trackPlays = trackPlays;
        /**
         * Active service subscriptions.
         */
        this.subscriptions = [];
        /**
         * Whether continuous playback should be
         * handled by the player after song ends.
         */
        this.handleContinuousPlayback = true;
        /**
         * Whether playback has been started via user gesture.
         *
         * If true, there's no need to maximize player overlay
         * anymore, because external controls will work properly.
         */
        this.playbackStartedViaGesture = false;
    }
    /**
     * Start the playback.
     */
    Player.prototype.play = function () {
        if (!this.ready())
            return;
        var track = this.queue.getCurrent();
        if (!track)
            return this.stop();
        this.setStrategy(track);
        this.maybeMaximizeOverlay();
        this.playbackStrategy.play();
    };
    /**
     * Pause the playback.
     */
    Player.prototype.pause = function () {
        this.playbackStrategy.pause();
    };
    /**
     * Play or pause player based on current playback state.
     */
    Player.prototype.togglePlayback = function () {
        if (this.isPlaying()) {
            this.pause();
        }
        else {
            this.play();
        }
    };
    /**
     * Check if current playback strategy is ready.
     */
    Player.prototype.ready = function () {
        return this.playbackStrategy.ready();
    };
    /**
     * Check if playback is in progress.
     */
    Player.prototype.isPlaying = function () {
        return this.state.playing;
    };
    /**
     * Check if player has any or specified track cued.
     */
    Player.prototype.cued = function (track) {
        var cued = this.getCuedTrack() && this.getCuedTrack().id;
        if (!track)
            return cued;
        return cued && this.getCuedTrack() === track;
    };
    /**
     * Get player state service.
     */
    Player.prototype.getState = function () {
        return this.state;
    };
    /**
     * Get player queue service.
     */
    Player.prototype.getQueue = function () {
        return this.queue;
    };
    /**
     * Check if player is buffering currently..
     */
    Player.prototype.isBuffering = function () {
        return this.state.buffering;
    };
    /**
     * Check if player is muted.
     */
    Player.prototype.isMuted = function () {
        return this.state.muted;
    };
    /**
     * Get track that is currently cued.
     */
    Player.prototype.getCuedTrack = function () {
        if (!this.playbackStrategy)
            return null;
        return this.playbackStrategy.getCuedTrack();
    };
    /**
     * Mute player.
     */
    Player.prototype.mute = function () {
        this.playbackStrategy.mute();
        this.state.muted = true;
    };
    /**
     * Unmute player.
     */
    Player.prototype.unMute = function () {
        this.playbackStrategy.unMute();
        this.state.muted = false;
    };
    /**
     * Get current player volume.
     */
    Player.prototype.getVolume = function () {
        return this.volume;
    };
    /**
     * Set volume to a number between 0 and 100.
     */
    Player.prototype.setVolume = function (volume) {
        this.volume = volume;
        this.playbackStrategy.setVolume(volume);
        this.storage.set('player.volume', volume);
    };
    /**
     * Stop playback and seek to start of track.
     */
    Player.prototype.stop = function () {
        if (!this.state.playing)
            return;
        this.playbackStrategy.pause();
        this.seekTo(0);
        this.state.playing = false;
        this.state.firePlaybackStopped();
    };
    /**
     * Get time that has elapsed since playback start.
     */
    Player.prototype.getCurrentTime = function () {
        return this.playbackStrategy.getCurrentTime();
    };
    /**
     * Get total duration of track in seconds.
     */
    Player.prototype.getDuration = function () {
        return this.playbackStrategy.getDuration();
    };
    /**
     * Seek to specified time in track.
     */
    Player.prototype.seekTo = function (time) {
        this.playbackStrategy.seekTo(time);
        return new Promise(function (resolve) { return setTimeout(function () { return resolve(); }, 50); });
    };
    /**
     * Toggle between repeat, repeat one and no repeat modes.
     */
    Player.prototype.toggleRepeatMode = function () {
        if (this.state.repeating) {
            this.state.repeatingOne = true;
        }
        else if (this.state.repeatingOne) {
            this.state.repeatingOne = false;
            this.state.repeating = false;
        }
        else {
            this.state.repeating = true;
        }
    };
    /**
     * Play next track in queue based on current repeat setting.
     */
    Player.prototype.playNext = function () {
        this.stop();
        var track = this.queue.getCurrent();
        if (this.state.repeating && this.queue.isLast()) {
            track = this.queue.getFirst();
        }
        else if (!this.state.repeatingOne) {
            track = this.queue.getNext();
        }
        this.queue.select(track);
        this.play();
    };
    /**
     * Play previous track in queue based on current repeat setting.
     */
    Player.prototype.playPrevious = function () {
        this.stop();
        var track = this.queue.getCurrent();
        if (this.state.repeating && this.queue.isFirst()) {
            track = this.queue.getLast();
        }
        else if (!this.state.repeatingOne) {
            track = this.queue.getPrevious();
        }
        this.queue.select(track);
        this.play();
    };
    /**
     * Toggle player shuffle mode.
     */
    Player.prototype.toggleShuffle = function () {
        if (this.state.shuffling) {
            this.queue.restoreOriginal();
        }
        else {
            this.queue.shuffle();
        }
        this.state.shuffling = !this.state.shuffling;
    };
    /**
     * Override player queue and cue first track.
     */
    Player.prototype.overrideQueue = function (params, queuePointer) {
        if (queuePointer === void 0) { queuePointer = 0; }
        this.putQueueIntoLocalStorage(params.tracks);
        this.queue.override(params, queuePointer);
        return this.cueTrack(this.queue.getCurrent());
    };
    /**
     * Cue specified track for playback.
     */
    Player.prototype.cueTrack = function (track) {
        var _this = this;
        var promise;
        this.setStrategy(track);
        if (!track || !this.playbackStrategy) {
            promise = new Promise(function (resolve) { return resolve(); });
        }
        else {
            this.queue.select(track);
            promise = this.playbackStrategy.cueTrack(track);
        }
        return promise.then(function () {
            _this.state.buffering = false;
        });
    };
    /**
     * Get currently active playback strategy.
     */
    Player.prototype.getPlaybackStrategy = function () {
        return this.activePlaybackStrategy;
    };
    /**
     * Init the player.
     */
    Player.prototype.init = function () {
        this.loadStateFromLocalStorage();
        this.setStrategy(this.queue.getCurrent());
        this.setInitialVolume();
        this.cueTrack(this.queue.getCurrent());
        this.bindToPlaybackStateEvents();
        this.initKeybinds();
    };
    /**
     * Destroy the player.
     */
    Player.prototype.destroy = function () {
        this.playbackStrategy && this.playbackStrategy.destroy();
        this.state.playing = false;
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
    };
    /**
     * Put specified queue into local storage and limit tracks to 15.
     */
    Player.prototype.putQueueIntoLocalStorage = function (tracks) {
        if (!tracks)
            return;
        this.storage.set('player.queue', { tracks: tracks.slice(0, 15) });
    };
    /**
     * Set playback strategy based on specified track.
     */
    Player.prototype.setStrategy = function (track) {
        if (track && track.url) {
            this.playbackStrategy = this.html5;
            this.activePlaybackStrategy = 'html5';
        }
        else if (this.settings.get('audio_search_provider') === 'soundcloud') {
            this.playbackStrategy = this.soundcloud;
            this.activePlaybackStrategy = 'soundcloud';
        }
        else {
            this.playbackStrategy = this.youtube;
            this.activePlaybackStrategy = 'youtube';
        }
        //destroy all except current active playback strategy
        if (this.activePlaybackStrategy !== 'youtube')
            this.youtube.destroy();
        if (this.activePlaybackStrategy !== 'html5')
            this.html5.destroy();
        if (this.activePlaybackStrategy !== 'soundcloud')
            this.soundcloud.destroy();
        return this.playbackStrategy;
    };
    Player.prototype.loadStateFromLocalStorage = function () {
        this.state.muted = this.storage.get('player.muted', false);
        this.state.repeating = this.storage.get('player.repeating', true);
        this.state.repeatingOne = this.storage.get('player.repeatingOne', false);
        this.state.shuffling = this.storage.get('player.shuffling', false);
        var queuePointer = this.storage.get('player.queue.pointer', 0);
        this.queue.override(this.storage.get('player.queue', { tracks: [] }), queuePointer);
    };
    /**
     * Set initial player volume.
     */
    Player.prototype.setInitialVolume = function () {
        var defaultVolume = this.settings.get('player.default_volume', 30);
        defaultVolume = this.storage.get('player.volume', defaultVolume);
        this.setVolume(defaultVolume);
        this.html5.setVolume(defaultVolume);
    };
    /**
     * Maximize fullscreen overlay if we're on mobile,
     * because youtube embed needs to be visible to start
     * playback with external youtube iframe api controls
     */
    Player.prototype.maybeMaximizeOverlay = function () {
        return __awaiter(this, void 0, void 0, function () {
            var shouldOpen;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        shouldOpen = this.settings.get('player.mobile.auto_open_overlay');
                        if (this.playbackStartedViaGesture || !shouldOpen || !this.globalState.isMobile)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.overlay.maximize()];
                    case 1:
                        _a.sent();
                        this.playbackStartedViaGesture = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Play next track when current track ends.
     */
    Player.prototype.bindToPlaybackStateEvents = function () {
        var _this = this;
        this.state.onChange$.subscribe(function (type) {
            if (type === 'PLAYBACK_STARTED') {
                _this.trackPlays.increment(_this.getCuedTrack());
            }
            else if (type === 'PLAYBACK_ENDED' && _this.handleContinuousPlayback) {
                _this.trackPlays.clearPlayedTrack(_this.getCuedTrack());
                _this.playNext();
            }
        });
    };
    /**
     * Initiate player keyboard shortcuts.
     */
    Player.prototype.initKeybinds = function () {
        var _this = this;
        var sub = this.browserEvents.globalKeyDown$.subscribe(function (e) {
            //SPACE - toggle playback
            if (e.keyCode === _this.browserEvents.keyCodes.space) {
                _this.togglePlayback();
                e.preventDefault();
                //ctrl+right - play next track
            }
            else if (e.ctrlKey && e.keyCode === _this.browserEvents.keyCodes.arrowRight) {
                _this.playNext();
                e.preventDefault();
            }
            //ctrl+left - play previous track
            else if (e.ctrlKey && e.keyCode === _this.browserEvents.keyCodes.arrowLeft) {
                _this.playPrevious();
                e.preventDefault();
            }
        });
        this.subscriptions.push(sub);
    };
    return Player;
}());
export { Player };
