var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { WebPlayerUrls } from "../../web-player-urls.service";
import { FormattedDuration } from "../../player/formatted-duration.service";
import { Player } from "../../player/player.service";
import { AlbumContextMenuComponent } from "../album-context-menu/album-context-menu.component";
import { WpUtils } from "../../web-player-utils";
import { ContextMenu } from 'common/core/ui/context-menu/context-menu.service';
var AlbumComponent = /** @class */ (function () {
    /**
     * AlbumComponent Constructor.
     */
    function AlbumComponent(route, urls, duration, player, contextMenu) {
        this.route = route;
        this.urls = urls;
        this.duration = duration;
        this.player = player;
        this.contextMenu = contextMenu;
    }
    AlbumComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.setAlbum(data.album);
            var total = _this.album.tracks.reduce(function (total, track2) { return total + track2.duration; }, 0);
            _this.totalDuration = _this.duration.fromMilliseconds(total);
        });
    };
    /**
     * Check if album is playing currently.
     */
    AlbumComponent.prototype.playing = function () {
        return this.player.state.playing && this.player.queue.getQueuedItem() === this.album.id;
    };
    /**
     * Play all album tracks.
     */
    AlbumComponent.prototype.play = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.player.stop();
                this.player.state.buffering = true;
                this.player.overrideQueue({
                    tracks: this.album.tracks,
                    queuedItemId: this.album.id
                }).then(function () {
                    _this.player.play();
                });
                return [2 /*return*/];
            });
        });
    };
    /**
     * Pause the album.
     */
    AlbumComponent.prototype.pause = function () {
        this.player.pause();
    };
    /**
     * Open album context menu.
     */
    AlbumComponent.prototype.openContextMenu = function (e) {
        e.stopPropagation();
        this.contextMenu.open(AlbumContextMenuComponent, e.target, { data: { item: this.album, type: 'album' } });
    };
    /**
     * Set album object on each album track.
     */
    AlbumComponent.prototype.setAlbum = function (album) {
        var simplifiedAlbum = Object.assign({}, album, { tracks: [] });
        album.tracks = WpUtils.assignAlbumToTracks(album.tracks, simplifiedAlbum);
        this.album = album;
    };
    return AlbumComponent;
}());
export { AlbumComponent };
