/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./context-menu-playlist-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/core/translations/translate.directive";
import * as i3 from "../../../../common/core/translations/translations.service";
import * as i4 from "../../../../common/core/config/settings.service";
import * as i5 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i6 from "@angular/material/icon";
import * as i7 from "../../../../common/core/ui/custom-scrollbar/custom-scrollbar.directive";
import * as i8 from "../../../../common/core/ui/breakpoints.service";
import * as i9 from "@angular/cdk/observers";
import * as i10 from "../../../../common/core/ui/custom-scrollbar/supports-native-scrollbar-styling";
import * as i11 from "@angular/common";
import * as i12 from "./context-menu-playlist-panel.component";
import * as i13 from "../../playlists/user-playlists.service";
import * as i14 from "../../playlists/playlists.service";
import * as i15 from "../../../../common/core/ui/context-menu/context-menu.service";
import * as i16 from "../../../../common/core/ui/dialogs/modal.service";
import * as i17 from "../../../../common/auth/current-user";
import * as i18 from "@angular/router";
import * as i19 from "../../../../common/core/ui/toast.service";
var styles_ContextMenuPlaylistPanelComponent = [i0.styles];
var RenderType_ContextMenuPlaylistPanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ContextMenuPlaylistPanelComponent, data: {} });
export { RenderType_ContextMenuPlaylistPanelComponent as RenderType_ContextMenuPlaylistPanelComponent };
function View_ContextMenuPlaylistPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "playlist no-style"], ["trans", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addTracks(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
export function View_ContextMenuPlaylistPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "button", [["class", "no-style panel-header"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePanel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "arrow-back"]], [[2, "mat-icon-inline", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(6, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Back"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(10, 0, null, null, 22, "div", [["class", "panel-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "title"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(13, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Select"])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(16, 0, null, null, 8, "button", [["class", "no-style new-playlist"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openNewPlaylistModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "playlist-add"]], [[2, "mat-icon-inline", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(19, 638976, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(22, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["New Playlist"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(26, 0, null, null, 5, "div", [["class", "playlists"], ["customScrollbar", ""]], null, null, null, null, null)), i1.ɵdid(27, 4341760, null, 0, i7.CustomScrollbarDirective, [i1.ElementRef, i1.Renderer2, i8.BreakpointsService, i1.NgZone, i9.MutationObserverFactory, i4.Settings, i10.SUPPORTS_NATIVE_SCROLLBAR_STYLING], null, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContextMenuPlaylistPanelComponent_1)), i1.ɵdid(30, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "arrow-back"; _ck(_v, 3, 0, currVal_1); var currVal_3 = "playlist-add"; _ck(_v, 19, 0, currVal_3); var currVal_4 = _co.userPlaylists.get(); _ck(_v, 30, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 19).inline; _ck(_v, 18, 0, currVal_2); }); }
export function View_ContextMenuPlaylistPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "context-menu-playlist-panel", [], null, null, null, View_ContextMenuPlaylistPanelComponent_0, RenderType_ContextMenuPlaylistPanelComponent)), i1.ɵdid(1, 49152, null, 0, i12.ContextMenuPlaylistPanelComponent, [i13.UserPlaylists, i14.Playlists, i15.ContextMenu, i16.Modal, i17.CurrentUser, i18.Router, i19.Toast], null, null)], null, null); }
var ContextMenuPlaylistPanelComponentNgFactory = i1.ɵccf("context-menu-playlist-panel", i12.ContextMenuPlaylistPanelComponent, View_ContextMenuPlaylistPanelComponent_Host_0, { tracks: "tracks" }, { close$: "close$" }, []);
export { ContextMenuPlaylistPanelComponentNgFactory as ContextMenuPlaylistPanelComponentNgFactory };
