var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Track } from "../../models/Track";
import { Player } from "../player/player.service";
import { Translations } from "common/core/translations/translations.service";
var RadioPageComponent = /** @class */ (function () {
    /**
     * RadioPageComponent Constructor.
     */
    function RadioPageComponent(route, player, i18n) {
        this.route = route;
        this.player = player;
        this.i18n = i18n;
    }
    RadioPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.seed = data.radio.seed;
            _this.type = _this.i18n.t(data.radio.type);
            _this.tracks = data.radio.recommendations.map(function (track) {
                return new Track(track);
            });
        });
    };
    /**
     * Get image for this radio.
     */
    RadioPageComponent.prototype.getImage = function () {
        return this.seed['image_small'] || this.seed['album'].image;
    };
    /**
     * Check if this radio is playing currently.
     */
    RadioPageComponent.prototype.playing = function () {
        return this.player.isPlaying() && this.isQueued();
    };
    /**
     * Play the radio.
     */
    RadioPageComponent.prototype.play = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.isQueued()) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.player.overrideQueue({ tracks: this.tracks, queuedItemId: this.getQueueId() })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.player.play();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Pause the player.
     */
    RadioPageComponent.prototype.pause = function () {
        this.player.pause();
    };
    /**
     * Get queue ID for this radio.
     */
    RadioPageComponent.prototype.getQueueId = function () {
        return 'radio.' + this.seed.id;
    };
    /**
     * Check if this radio is currently queued in player.
     */
    RadioPageComponent.prototype.isQueued = function () {
        return this.player.queue.itemIsQueued(this.getQueueId());
    };
    return RadioPageComponent;
}());
export { RadioPageComponent };
