var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ElementRef, EventEmitter, NgZone, OnChanges, OnDestroy, OnInit, QueryList } from '@angular/core';
import { Track } from "../../../models/Track";
import { Player } from "../../player/player.service";
import { FormattedDuration } from "../../player/formatted-duration.service";
import { Album } from "../../../models/Album";
import { WpUtils } from "../../web-player-utils";
import { WebPlayerUrls } from "../../web-player-urls.service";
import { TrackContextMenuComponent } from "../track-context-menu/track-context-menu.component";
import { PlaylistTrackContextMenuComponent } from "../../playlists/playlist-track-context-menu/playlist-track-context-menu.component";
import { SelectedTracks } from "./selected-tracks.service";
import { WebPlayerState } from "../../web-player-state.service";
import { BrowserEvents } from "common/core/services/browser-events.service";
import { ContextMenu } from 'common/core/ui/context-menu/context-menu.service';
var TrackListComponent = /** @class */ (function () {
    /**
     * TrackListComponent Constructor.
     */
    function TrackListComponent(player, duration, urls, contextMenu, zone, el, selectedTracks, browserEvents, state) {
        this.player = player;
        this.duration = duration;
        this.urls = urls;
        this.contextMenu = contextMenu;
        this.zone = zone;
        this.el = el;
        this.selectedTracks = selectedTracks;
        this.browserEvents = browserEvents;
        this.state = state;
        /**
         * Active component subscriptions.
         */
        this.subscriptions = [];
        /**
         * Tracks to render.
         */
        this.tracks = [];
        /**
         * Album tracks belong to.
         */
        this.album = new Album();
        /**
         * Whether artist column should be displayed.
         */
        this.showArtist = false;
        /**
         * Whether album column should be displayed.
         */
        this.showAlbum = false;
        /**
         * Whether popularity column should be displayed.
         */
        this.showPopularity = true;
        /**
         * Whether added at column should be displayed.
         */
        this.showAddedAt = false;
        /**
         * Whether header should be displayed.
         */
        this.showHeader = true;
        /**
         * Whether player should be set to handle continuous
         * playback when track is played from this track list.
         */
        this.handleContinuousPlayback = true;
        /**
         * Params for context menu that should be opened for tracks.
         */
        this.contextMenuParams = { type: 'track', extra: {} };
        /**
         * Fired when delete button is pressed when tracks are selected.
         */
        this.delete = new EventEmitter();
        /**
         * Fired when play button is pressed.
         */
        this.play = new EventEmitter();
    }
    TrackListComponent.prototype.ngOnInit = function () {
        this.bindHammerEvents();
        this.bindKeyboardShortcuts();
        if (this.select) {
            this.selectedTracks.add(this.select);
        }
    };
    TrackListComponent.prototype.ngOnChanges = function () {
        this.formatTrackDurations();
    };
    TrackListComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
    };
    /**
     * Check if specified track is cued and playing.
     */
    TrackListComponent.prototype.trackIsPlaying = function (track) {
        return this.player.isPlaying() && this.player.cued(track);
    };
    /**
     * Add tracks from specified index to queue and start playback.
     */
    TrackListComponent.prototype.playTrack = function (track, index) {
        this.play.emit(track);
        //if there's an observer for play event, bail
        //and let the observer handle playback
        if (this.play.observers.length)
            return;
        this.player.handleContinuousPlayback = this.handleContinuousPlayback;
        if (this.player.cued(track)) {
            this.player.play();
        }
        else {
            this.playFrom(index);
        }
    };
    /**
     * Play or pause specified track.
     */
    TrackListComponent.prototype.toggleTrackPlayback = function (track, index) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.trackIsPlaying(track)) {
                    this.player.pause();
                }
                else {
                    this.playTrack(track, index);
                }
                return [2 /*return*/];
            });
        });
    };
    /**
     * Get formatted track popularity value.
     */
    TrackListComponent.prototype.getTrackPopularity = function (track) {
        if (!track.spotify_popularity) {
            track.spotify_popularity = 50;
        }
        return track.spotify_popularity + '%';
    };
    /**
     * Show context menu for specified track.
     */
    TrackListComponent.prototype.showContextMenu = function (track, e) {
        e.stopPropagation();
        e.preventDefault();
        this.contextMenu.open(this.getContextMenuComponent(), e.target, { data: this.getContextMenuParams(track) });
    };
    /**
     * Get params needed to open context menu for track.
     */
    TrackListComponent.prototype.getContextMenuParams = function (track) {
        return Object.assign({ item: track, type: this.contextMenuParams.type, selectedTracks: this.selectedTracks }, this.contextMenuParams.extra);
    };
    /**
     * Get context menu component based on specified type.
     */
    TrackListComponent.prototype.getContextMenuComponent = function () {
        if (this.contextMenuParams.type === 'playlistTrack') {
            return PlaylistTrackContextMenuComponent;
        }
        else {
            return TrackContextMenuComponent;
        }
    };
    /**
     * Add tracks from specified index to player queue and start playback.
     */
    TrackListComponent.prototype.playFrom = function (index) {
        var _this = this;
        var tracks = this.tracks.slice(index, this.tracks.length);
        tracks = WpUtils.assignAlbumToTracks(tracks, this.album);
        this.player.overrideQueue({ tracks: tracks, queuedItemId: this.queueItemId }).then(function () {
            _this.player.play();
        });
    };
    /**
     * Format duration of every track to human readable format.
     */
    TrackListComponent.prototype.formatTrackDurations = function () {
        var _this = this;
        this.tracks.forEach(function (track) {
            track['formatted_duration'] = _this.duration.fromMilliseconds(track.duration);
        });
    };
    /**
     * Bind handlers to needed hammer.js events.
     */
    TrackListComponent.prototype.bindHammerEvents = function () {
        var _this = this;
        var hammer, singleTap, doubleTap;
        this.zone.runOutsideAngular(function () {
            hammer = new Hammer.Manager(_this.el.nativeElement);
            singleTap = new Hammer.Tap({ event: 'singletap' });
            doubleTap = new Hammer.Tap({ event: 'doubletap', taps: 2 });
            hammer.add([doubleTap, singleTap]);
        });
        //select track on tap or multiple tracks via when ctrl is pressed
        hammer.on('singletap', function (e) {
            _this.zone.run(function () {
                var data = _this.getTrackFromEvent(e);
                if (!data)
                    return;
                if (!e.srcEvent.ctrlKey) {
                    _this.selectedTracks.clear();
                    _this.selectedTracks.add(data.track);
                }
                else {
                    _this.selectedTracks.toggle(data.track);
                }
            });
        });
        //play track on double tap
        hammer.on('doubletap', function (e) {
            _this.zone.run(function () {
                var data = _this.getTrackFromEvent(e);
                if (!data)
                    return;
                _this.playTrack(data.track, data.index);
            });
        });
        //deselect all tracks when clicked outside of track list.
        var sub = this.browserEvents.globalClick$.subscribe(function (e) {
            if (!e.target.closest('.track-list-row')) {
                _this.selectedTracks.clear();
            }
        });
        this.subscriptions.push(sub);
    };
    /**
     * Get track from specified hammer tap event.
     */
    TrackListComponent.prototype.getTrackFromEvent = function (e) {
        if (!e.target)
            return;
        var row = e.target.closest('.track-list-item');
        if (!row)
            return;
        var id = parseInt(row.getAttribute('data-id'));
        var i = this.tracks.findIndex(function (track) { return track.id === id; });
        return { track: this.tracks[i], index: i };
    };
    /**
     * Initiate tracks list shortcuts.
     */
    TrackListComponent.prototype.bindKeyboardShortcuts = function () {
        var _this = this;
        var sub = this.browserEvents.globalKeyDown$.subscribe(function (e) {
            //ctrl+a - select all tracks
            if (e.ctrlKey && e.keyCode === _this.browserEvents.keyCodes.letters.a) {
                _this.tracks.forEach(function (track) { return _this.selectedTracks.add(track); });
                e.preventDefault();
            }
            //delete - fire delete event
            else if (e.keyCode === _this.browserEvents.keyCodes.delete && !_this.selectedTracks.empty()) {
                _this.delete.emit(_this.selectedTracks.all());
                _this.selectedTracks.clear();
                e.preventDefault();
            }
        });
        this.subscriptions.push(sub);
    };
    return TrackListComponent;
}());
export { TrackListComponent };
