var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, EventEmitter } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { InfiniteScroll } from '../../common/core/ui/infinite-scroll/infinite.scroll';
var InfiniteScrollDirective = /** @class */ (function (_super) {
    __extends(InfiniteScrollDirective, _super);
    function InfiniteScrollDirective(el) {
        var _this = _super.call(this) || this;
        _this.el = el;
        _this.infiniteScrollEnabled = false;
        _this.onInfiniteScroll = new EventEmitter();
        return _this;
    }
    InfiniteScrollDirective.prototype.ngOnInit = function () {
        var _this = this;
        fromEvent(this.getScrollContainer(), 'scroll', { capture: true })
            .pipe(debounceTime(20))
            .subscribe(function (e) { return _this.onScroll(e.target); });
    };
    InfiniteScrollDirective.prototype.canLoadMore = function () {
        return this.infiniteScrollEnabled;
    };
    InfiniteScrollDirective.prototype.isLoading = function () {
        return false;
    };
    InfiniteScrollDirective.prototype.loadMoreItems = function () {
        this.onInfiniteScroll.emit();
    };
    return InfiniteScrollDirective;
}(InfiniteScroll));
export { InfiniteScrollDirective };
