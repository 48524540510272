var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UserLibrary } from "./web-player/users/user-library/user-library.service";
import { UserPlaylists } from "./web-player/playlists/user-playlists.service";
import { Bootstrapper } from "common/core/bootstrapper.service";
var BeMusicBootstrapper = /** @class */ (function (_super) {
    __extends(BeMusicBootstrapper, _super);
    function BeMusicBootstrapper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Handle specified bootstrap data.
     */
    BeMusicBootstrapper.prototype.handleData = function (encodedData) {
        var data = _super.prototype.handleData.call(this, encodedData);
        //set user library
        this.injector.get(UserLibrary).setTrackIds(data['tracks']);
        //set user playlists
        this.injector.get(UserPlaylists).set(data['playlists']);
        return data;
    };
    return BeMusicBootstrapper;
}(Bootstrapper));
export { BeMusicBootstrapper };
