/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./repeat-button.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/material/tooltip";
import * as i8 from "@angular/cdk/overlay";
import * as i9 from "@angular/cdk/scrolling";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i12 from "@angular/material/icon";
import * as i13 from "../../../../../common/core/translations/translate.pipe";
import * as i14 from "../../../../../common/core/translations/translations.service";
import * as i15 from "@angular/common";
import * as i16 from "./repeat-button.component";
import * as i17 from "../../player.service";
var styles_RepeatButtonComponent = [i0.styles];
var RenderType_RepeatButtonComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_RepeatButtonComponent, data: {} });
export { RenderType_RepeatButtonComponent as RenderType_RepeatButtonComponent };
function View_RepeatButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 7, "button", [["class", "player-action repeat-button"], ["mat-icon-button", ""]], [[2, "active", null], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.player.toggleRepeatMode() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 147456, null, 0, i7.MatTooltip, [i8.Overlay, i1.ElementRef, i9.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i4.Platform, i5.AriaDescriber, i5.FocusMonitor, i7.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i10.Directionality], [2, i7.MAT_TOOLTIP_DEFAULT_OPTIONS]], { message: [0, "message"] }, null), i1.ɵppd(3, 1), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "repeat"]], [[2, "mat-icon-inline", null]], null, null, i11.View_MatIcon_0, i11.RenderType_MatIcon)), i1.ɵdid(6, 638976, null, 0, i12.MatIcon, [i1.ElementRef, i12.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n"])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_3 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), "Repeat")); _ck(_v, 2, 0, currVal_3); var currVal_5 = "repeat"; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.player.getState().repeating; var currVal_1 = (i1.ɵnov(_v, 1).disabled || null); var currVal_2 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i1.ɵnov(_v, 6).inline; _ck(_v, 5, 0, currVal_4); }); }
function View_RepeatButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 7, "button", [["class", "player-action repeat-button active"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.player.toggleRepeatMode() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 147456, null, 0, i7.MatTooltip, [i8.Overlay, i1.ElementRef, i9.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i4.Platform, i5.AriaDescriber, i5.FocusMonitor, i7.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i10.Directionality], [2, i7.MAT_TOOLTIP_DEFAULT_OPTIONS]], { message: [0, "message"] }, null), i1.ɵppd(3, 1), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "repeat-one"]], [[2, "mat-icon-inline", null]], null, null, i11.View_MatIcon_0, i11.RenderType_MatIcon)), i1.ɵdid(6, 638976, null, 0, i12.MatIcon, [i1.ElementRef, i12.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n"])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), "Repeat One")); _ck(_v, 2, 0, currVal_2); var currVal_4 = "repeat-one"; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 6).inline; _ck(_v, 5, 0, currVal_3); }); }
export function View_RepeatButtonComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i13.TransPipe, [i14.Translations]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RepeatButtonComponent_1)), i1.ɵdid(2, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RepeatButtonComponent_2)), i1.ɵdid(5, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.player.getState().repeatingOne; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.player.getState().repeatingOne; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_RepeatButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "repeat-button", [], null, null, null, View_RepeatButtonComponent_0, RenderType_RepeatButtonComponent)), i1.ɵdid(1, 49152, null, 0, i16.RepeatButtonComponent, [i17.Player], null, null)], null, null); }
var RepeatButtonComponentNgFactory = i1.ɵccf("repeat-button", i16.RepeatButtonComponent, View_RepeatButtonComponent_Host_0, {}, {}, []);
export { RepeatButtonComponentNgFactory as RepeatButtonComponentNgFactory };
