var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Artists } from "./artists.service";
import { Player } from "../player/player.service";
import { WpUtils } from "../web-player-utils";
import { MediaItem } from "../media-item.service";
import { map } from "rxjs/operators";
var ArtistService = /** @class */ (function (_super) {
    __extends(ArtistService, _super);
    /**
     * ArtistService Constructor.
     */
    function ArtistService(artists, player) {
        var _this = _super.call(this, player) || this;
        _this.artists = artists;
        _this.player = player;
        /**
         * Top artist tracks.
         */
        _this.topTracks = [];
        return _this;
    }
    /**
     * Get artist top tracks.
     */
    ArtistService.prototype.getTopTracks = function (count) {
        if (count === void 0) { count = 5; }
        return this.topTracks.slice(0, count);
    };
    /**
     * Get similar artists.
     */
    ArtistService.prototype.getSimilar = function (count) {
        if (count === void 0) { count = 20; }
        if (!this.item.similar)
            return [];
        return this.item.similar.slice(0, count);
    };
    /**
     * Check if artist has any similar artists.
     */
    ArtistService.prototype.hasSimilar = function () {
        return this.item.similar.length;
    };
    /**
     * Get loaded albums of artist.
     */
    ArtistService.prototype.getAlbums = function () {
        return this.paginatedData;
    };
    /**
     * Get artist biography and images.
     */
    ArtistService.prototype.getBio = function () {
        return this.item.bio;
    };
    /**
     * Destroy artist service.
     */
    ArtistService.prototype.destroy = function () {
        this.topTracks = [];
        _super.prototype.destroy.call(this);
    };
    /**
     * Load next infinite load albums page.
     */
    ArtistService.prototype.loadNextPage = function () {
        var _this = this;
        return this.artists.paginateArtistAlbums(this.item.id, this.pagination.current_page + 1).pipe(map(function (response) {
            response.data = _this.addArtistToAlbums(response.data);
            return response;
        }));
    };
    /**
     * Get tracks from specified albums.
     */
    ArtistService.prototype.getTracks = function (paginatedData) {
        var data = (paginatedData ? paginatedData : this.paginatedData), tracks = [];
        data.forEach(function (album) {
            tracks = tracks.concat(WpUtils.assignAlbumToTracks(album.tracks, album));
        });
        //if paginated tracks were not specified, it means initial
        //data is being requested, so we should prepend top tracks to it
        if (!paginatedData) {
            tracks = this.topTracks.concat(tracks);
        }
        return tracks;
    };
    /**
     * Load artist from backend.
     */
    ArtistService.prototype.loadItem = function (id, params) {
        if (params === void 0) { params = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.artists.get(id, params).toPromise()];
                    case 1:
                        response = _a.sent();
                        response.albums.data = this.addArtistToAlbums(response.albums.data, response.artist);
                        this.setItem(response.artist, response.albums);
                        this.topTracks = response.top_tracks || [];
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Add simplified artist object to all albums, for generating urls and context menus.
     */
    ArtistService.prototype.addArtistToAlbums = function (albums, artist) {
        if (!artist)
            artist = this.item;
        if (!artist)
            return;
        return albums.map(function (album) {
            album.artist = { name: artist.name, id: artist.id };
            return album;
        });
    };
    return ArtistService;
}(MediaItem));
export { ArtistService };
