var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { WebPlayerUrls } from "../../web-player-urls.service";
import { FilterablePage } from "../../filterable-page/filterable-page";
import { ActivatedRoute } from "@angular/router";
import { Settings } from "common/core/config/settings.service";
import { WebPlayerImagesService } from "../../web-player-images.service";
var PopularGenresComponent = /** @class */ (function (_super) {
    __extends(PopularGenresComponent, _super);
    /**
     * PopularGenresComponent Constructor.
     */
    function PopularGenresComponent(route, urls, settings, wpImages) {
        var _this = _super.call(this, ['name']) || this;
        _this.route = route;
        _this.urls = urls;
        _this.settings = settings;
        _this.wpImages = wpImages;
        return _this;
    }
    PopularGenresComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.setItems(data.genres);
        });
    };
    return PopularGenresComponent;
}(FilterablePage));
export { PopularGenresComponent };
