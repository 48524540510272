/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filterable-page-header.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../common/core/translations/translate.directive";
import * as i4 from "../../../../common/core/translations/translations.service";
import * as i5 from "../../../../common/core/config/settings.service";
import * as i6 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "./filterable-page-header.component";
import * as i9 from "../../../../common/core/services/browser-events.service";
import * as i10 from "../../web-player-state.service";
var styles_FilterablePageHeaderComponent = [i0.styles];
var RenderType_FilterablePageHeaderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FilterablePageHeaderComponent, data: {} });
export { RenderType_FilterablePageHeaderComponent as RenderType_FilterablePageHeaderComponent };
export function View_FilterablePageHeaderComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { filterInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(4, 0, null, null, 15, "div", [["class", "filter-bar input-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "label", [["class", "hidden"], ["for", "popular-albums-filter"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Filter items"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(9, 0, [[1, 0], ["filterInput", 1]], null, 6, "input", [["id", "popular-albums-filter"], ["placeholder", "Filter..."], ["trans-placeholder", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 10)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(12, 540672, null, 0, i2.FormControlDirective, [[8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_j]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlDirective]), i1.ɵdid(14, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(15, 4341760, null, 0, i3.TranslateDirective, [i1.ElementRef, i4.Translations, i5.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "mat-icon", [["class", "size-22 mat-icon"], ["role", "img"], ["svgIcon", "search"]], [[2, "mat-icon-inline", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(18, 638976, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.filterQuery; _ck(_v, 12, 0, currVal_7); var currVal_9 = "search"; _ck(_v, 18, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 14).ngClassValid; var currVal_5 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 9, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 18).inline; _ck(_v, 17, 0, currVal_8); }); }
export function View_FilterablePageHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "filterable-page-header", [], [[2, "hidden", null]], null, null, View_FilterablePageHeaderComponent_0, RenderType_FilterablePageHeaderComponent)), i1.ɵdid(1, 245760, null, 0, i8.FilterablePageHeaderComponent, [i9.BrowserEvents, i10.WebPlayerState], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isHidden; _ck(_v, 0, 0, currVal_0); }); }
var FilterablePageHeaderComponentNgFactory = i1.ɵccf("filterable-page-header", i8.FilterablePageHeaderComponent, View_FilterablePageHeaderComponent_Host_0, { filterQuery: "filterQuery" }, {}, ["*"]);
export { FilterablePageHeaderComponentNgFactory as FilterablePageHeaderComponentNgFactory };
