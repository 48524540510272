var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Player } from "./player/player.service";
var MediaItem = /** @class */ (function () {
    /**
     * MediaItem Service Constructor.
     */
    function MediaItem(player) {
        this.player = player;
        /**
         * Whether service is already bootstrapped.
         */
        this.bootstrapped = false;
        /**
         * Data that is being paginated for media item.
         */
        this.paginatedData = [];
        /**
         * Active service subscriptions.
         */
        this.subscriptions = [];
        /**
         * Whether items are being loaded currently.
         */
        this.loading = false;
    }
    /**
     * Play all media item tracks.
     */
    MediaItem.prototype.play = function (tracks) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.player.queue.itemIsQueued(this.item.id)) return [3 /*break*/, 2];
                        this.player.handleContinuousPlayback = false;
                        return [4 /*yield*/, this.player.overrideQueue({ tracks: tracks || this.getTracks(), queuedItemId: this.item.id })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.player.play();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Pause the playlist.
     */
    MediaItem.prototype.pause = function () {
        this.player.pause();
    };
    /**
     * Check if media item is playing currently.
     */
    MediaItem.prototype.playing = function (item) {
        if (!item)
            item = this.item;
        return this.player.state.playing && this.player.queue.getQueuedItem() === item['id'];
    };
    /**
     * Get the media item.
     */
    MediaItem.prototype.get = function () {
        return this.item;
    };
    /**
     * Set property on media item object.
     */
    MediaItem.prototype.set = function (prop, value) {
        this.item[prop] = value;
    };
    /**
     * Bootstrap the service.
     */
    MediaItem.prototype.init = function (id, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (this.bootstrapped && this.item && this.item.id === id)
            return;
        this.destroy();
        return this.loadItem(id, params).then(function (response) {
            _this.bindToPlaybackEndEvent();
            _this.bootstrapped = true;
            return response;
        }, function () { });
    };
    /**
     * Set item and pagination data on service.
     */
    MediaItem.prototype.setItem = function (item, pagination) {
        this.item = item;
        this.pagination = pagination;
        this.paginatedData = pagination.data;
    };
    /**
     * Destroy playlist service.
     */
    MediaItem.prototype.destroy = function () {
        this.unsubscribe();
        this.item = null;
        this.pagination = null;
        this.paginatedData = [];
        this.subscriptions = [];
        this.player.handleContinuousPlayback = true;
    };
    /**
     * Load more tracks for playlist.
     */
    MediaItem.prototype.loadMore = function () {
        var _this = this;
        this.loading = true;
        var page = this.pagination.current_page + 1;
        return this.loadNextPage(this.item.id, page).toPromise().then(function (response) {
            _this.pagination = response;
            _this.paginatedData = _this.paginatedData.concat(response.data);
            _this.player.queue.append(_this.getTracks(response.data));
        }).then(function () {
            _this.loading = false;
        });
    };
    /**
     * Check if more artists can be loaded for current genre.
     */
    MediaItem.prototype.canLoadMore = function () {
        return !this.loading && this.pagination.current_page < this.pagination.last_page;
    };
    /**
     * Lazy load more tracks from user library for
     * continuous playback after current track ends.
     */
    MediaItem.prototype.bindToPlaybackEndEvent = function () {
        var _this = this;
        var sub = this.player.state.onChange$.subscribe(function (type) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (type !== 'PLAYBACK_ENDED')
                            return [2 /*return*/];
                        //if player is not playing this artist currently, bail
                        if (this.player.queue.getQueuedItem() !== this.item.id)
                            return [2 /*return*/];
                        if (!(this.player.getQueue().isLast() && this.canLoadMore())) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadMore()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.player.playNext();
                        return [2 /*return*/];
                }
            });
        }); });
        this.subscriptions.push(sub);
    };
    /**
     * Unsubscribe from all subscriptions this service made.
     */
    MediaItem.prototype.unsubscribe = function () {
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
    };
    return MediaItem;
}());
export { MediaItem };
